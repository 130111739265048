import { createAction, handleActions } from "redux-actions"
import {
	all,
	put,
	call,
	take,
	takeEvery,
	select,
	fork,
} from "redux-saga/effects"
import { v4 as uuidv4 } from "uuid"
import {
	getLessonRequest,
	sendHomeWorkFormFinishRequest,
	sendHomeworkFormRequest,
	sendLessonStatusRequest,
	sendMediaRequest,
	sendPostAttachmentsRequest,
	sendQuizAnswerRequest,
	sendQuizFinishRequest,
	sendQuizStartRequest,
	tryAgainQuizRequest
} from "../../api/lesson/lesson"
import { addGlobalLoadItem, removeGlobalLoadItem } from "./loader"
import {
	setModules as setCourseModules,
	courseSelector,
	getCourse,
} from "./course"
import {
	successMessage,
	dangerMessage,
} from "../../components/UIKit/alert/alertReducer"
import { getProduct, productSelector } from "./products"
import { getCertificates } from "./certificates"

export const lessonMoveStatuses = {
	IN_PROGRESS: "in_progress",
	COMPLETED: "completed",
	SEND: "send",
}

const namespace = "lesson"

const initialState = {
	attachments: [],
	post: null,
	navigation: null,
	modules: null,
	questions: null,
	error: null,
}

const GET_LESSON = `${namespace}/GET_LESSON`
const SET_LESSON = `${namespace}/SET_LESSON`
const RESET_LESSON = `${namespace}/RESET_LESSON`

const SET_LESSON_STATUS = `${namespace}/SET_LESSON_STATUS`
const SEND_LESSON_STATUS = `${namespace}/SEND_LESSON_STATUS`

const SEND_HOMEWORK_FORM_START = `${namespace}/SEND_HOMEWORK_FORM_START`

const SEND_QUIZ_START = `${namespace}/SEND_QUIZ_START`
const SEND_QUIZ_ANSWER = `${namespace}/SEND_QUIZ_ANSWER`
const SET_QUESTIONS = `${namespace}/SET_QUESTIONS`
const SEND_QUIZ_FINISH = `${namespace}/SEND_QUIZ_FINISH`
const SET_PROGRESS = `${namespace}/SET_PROGRESS`

const SET_SCORE = `${namespace}/SET_SCORE`

const SET_MODULES = `${namespace}/SET_MODULES`
const SET_NAVIGATIONS = `${namespace}/SET_NAVIGATIONS`

const SET_ANSWERS = `${namespace}/SET_ANSWERS`

const SET_ERROR = `${namespace}/SET_ERROR`

const SEND_ATTACHMENTS = `${namespace}/SEND_ATTACHMENTS`
const TRY_AGAIN_QUIZ = `${namespace}/TRY_AGAIN_QUIZ`

export const getLesson = createAction(GET_LESSON)
export const setLesson = createAction(SET_LESSON)
export const resetLesson = createAction(RESET_LESSON)

export const setLessonStatus = createAction(SET_LESSON_STATUS)
export const sendLessonStatus = createAction(SEND_LESSON_STATUS)

export const sendHomeworkForm = createAction(SEND_HOMEWORK_FORM_START)

export const sendQuizStart = createAction(SEND_QUIZ_START)
export const sendQuizAnswer = createAction(SEND_QUIZ_ANSWER)
const setQuestions = createAction(SET_QUESTIONS)
export const sendQuizFinish = createAction(SEND_QUIZ_FINISH)
export const setProgress = createAction(SET_PROGRESS)
const setScore = createAction(SET_SCORE)

const setModules = createAction(SET_MODULES)
const setNavigatios = createAction(SET_NAVIGATIONS)
const setAnswers = createAction(SET_ANSWERS)

export const setError = createAction(SET_ERROR)
export const sendAttachments = createAction(SEND_ATTACHMENTS)

export const tryAgainQuiz = createAction(TRY_AGAIN_QUIZ)

export default handleActions(
	{
		[SET_LESSON]: (state, { payload }) => payload,
		[SET_LESSON_STATUS]: (state, { payload }) => ({
			...state,
			post: { ...state["post"], status: payload },
		}),
		[SET_QUESTIONS]: (state, { payload }) => ({ ...state, questions: payload }),
		[SET_PROGRESS]: (state, { payload }) => ({ ...state, progress: payload }),
		[SET_SCORE]: (state, { payload }) => ({ ...state, score: payload }),
		[SET_MODULES]: (state, { payload }) => ({ ...state, modules: payload }),
		[SET_NAVIGATIONS]: (state, { payload }) => ({
			...state,
			navigation: payload,
		}),
		[SET_ANSWERS]: (state, { payload }) => ({ ...state, answers: payload }),
		[RESET_LESSON]: () => initialState,
		[SET_ERROR]: (state, { payload }) => ({ ...state, error: payload }),
	},
	initialState
)

export const lessonSelector = (state) => state[namespace]
export const postSelector = (state) => state[namespace].post
export const attachmentsSelector = (state) => state[namespace].attachments
export const questionsSelector = (state) => state[namespace].questions
export const progressSelector = (state) => state[namespace].progress
export const scoreSelector = (state) => state[namespace]?.score || null
export const navigationSelector = (state) => state[namespace]?.navigation
export const answersSelector = (state) => state[namespace]?.answers || null
export const errorSelector = (state) => state[namespace]?.error

function* sendStatusSaga({ payload }) {
	yield put(setLessonStatus(lessonMoveStatuses.SEND))
	const { id, product } = yield select(postSelector)
	try {
		const { data } = yield call(sendLessonStatusRequest, id, payload)
		if (data.modules) {
			yield put(setModules(data.modules))
			yield put(setCourseModules(data.modules))
		}
		if (data.navigation) {
			yield put(setNavigatios(data.navigation))
		}
		yield put(setLessonStatus(payload))

		const lesson = yield select(lessonSelector)
		const productId = lesson?.post?.product?.id
		const params = { productId, hidePreloader: true }
		if (product?.path_id) {
			params["queryString"] = `filter[path_id]=${product?.path_id}`
		}
		yield put(getProduct(params)) 
		if(payload === "completed") {
			yield put(getCertificates())
		}
	} catch (err) {
		console.log(err)
	}
}

function* getLessonSaga() {
	while (true) {
		const { payload } = yield take(GET_LESSON)
		yield put(resetLesson())
		yield put(addGlobalLoadItem(GET_LESSON))
		try {
			const { data } = yield call(getLessonRequest, payload)
			yield put(setLesson(data))
		} catch (err) {
			console.log(err)

			if (err.response.status === 404) {
				yield put(setError(404))
			}

			if (err.response.status === 422) {
				yield put(setError(422))
			}
		} finally {
			yield put(removeGlobalLoadItem(GET_LESSON))
		}
	}
}
function prepareAttachments(attachments) {
	if (!attachments?.length) {
		return { attachments: [], files: null }
	}
	const res = []
	const files = {}
	for (let i = 0; i < attachments?.length; i++) {
		const file = attachments[i]
		const uuid = uuidv4()
		files[uuid] = attachments[i]
		res.push({
			uuid,
			mime: file?.name?.split(".")?.pop(),
			original_filename: file?.name,
			size: file?.size,
		})
	}
	return { attachments: res, files }
}

function* sendAttachmentsSaga() {
	while (true) {
		const {
			payload: { postId, answer, attachments, files, cb, person_answer_id, setSubmitting },
		} = yield take(SEND_ATTACHMENTS)
		const attachmentsArr = []
		const reqArr = attachments.map(function* (a) {
			const { fields, uuid, url } = a
			attachmentsArr.push({
				filename: a?.filename,
				attachment_id: a?.attachment_id,
			})
			const formData = new FormData()
			Object.keys(fields).forEach((k) => {
				formData.append(k, fields[k])
			})
			formData.append("file", files[uuid])
			return yield call(sendMediaRequest, url, formData)
		})

		try {
			yield all(reqArr)
			const data = {
				answer,
				attachments: attachmentsArr,
				postId,
				cb,
				person_answer_id,
				setSubmitting,
			}
			yield fork(sendHomeWorkFormFinishSaga, data)
		} catch (err) {
			console.log("err", err)
			setSubmitting(false)
		}
	}
}

function* sendHomeWorkFormFinishSaga({ postId, cb, setSubmitting, ...other }) {
	const { product } = yield select(postSelector)
	try {
		const { data } = yield call(sendHomeWorkFormFinishRequest, {
			postId,
			data: other,
		})
		if (data.modules) {
			yield put(setModules(data.modules))
			yield put(setCourseModules(data.modules))
		}
		if (data.navigation) {
			yield put(setNavigatios(data.navigation))
		}
		if (data.answer) {
			const { answer } = data
			const answers = yield select(answersSelector)
			const newAnswers = answers.filter((a) => a.id !== answer.id)

			yield put(setAnswers([answer, ...newAnswers]))
		}
		typeof cb === "function" && cb()

		const lesson = yield select(lessonSelector)
		const productId = lesson?.post?.product?.id
		const params = { productId }
		if (product?.path_id) {
			params["queryString"] = `filter[path_id]=${product?.path_id}`
		}

		yield put(getProduct(params))
	} catch (err) {
		console.log("err", err)
		setSubmitting(false)
	} finally {
		setSubmitting(false)
	}
}

function* sendHomeWorkFormStart() {
	while (true) {
		const { payload } = yield take(SEND_HOMEWORK_FORM_START)
		const { attachment, postId, answer, cb, setSubmitting } = payload
		const { attachments, files } = prepareAttachments(attachment)
		try {
			setSubmitting(true)
			const { data } = yield call(sendPostAttachmentsRequest, {
				postId,
				data: { answer, attachments },
			})
			if(!attachments?.length){
				const values = {
					postId,
					answer,
					attachments: data?.attachments,
					person_answer_id: data?.person_answer_id,
					files,
					cb,
					setSubmitting,
				}
				yield fork(sendHomeWorkFormFinishSaga, values)
			}else{
				yield put(
					sendAttachments({
						postId,
						answer,
						attachments: data?.attachments,
						person_answer_id: data?.person_answer_id,
						files,
						cb,
						setSubmitting,
					})
				)
			}
			
		} catch (err) {
			console.log("err", err)
			setSubmitting(false)
		}
	}
}

function* sendQuizStartSaga() {
	while (true) {
		const { payload } = yield take(SEND_QUIZ_START)
		const { id, product } = yield select(postSelector)
		try {
			const { data } = yield call(sendQuizStartRequest, payload)
			yield put(successMessage(data.message))
			if (data) {
				yield put(setLessonStatus(lessonMoveStatuses.IN_PROGRESS))
				if (data.modules) {
					yield put(setModules(data.modules))
					yield put(setCourseModules(data.modules))
				}
				if (data.navigation) {
					yield put(setNavigatios(data.navigation))
				}

				const lesson = yield select(lessonSelector)
				const productId = lesson?.post?.product?.id

				const params = { productId }
				if (product?.path_id) {
					params["queryString"] = `filter[path_id]=${product?.path_id}`
				}

				yield put(getProduct(params))
			}
		} catch (err) {
			console.log(err)
		}
	}
}

function* sendQuizAnswerSaga() {
	while (true) {
		const { payload } = yield take(SEND_QUIZ_ANSWER)
		const { data: dataAnswer, cb, lessonId, progress, questionId, hideToast } = payload
		try {
			const { data } = yield call(sendQuizAnswerRequest, lessonId, questionId, {
				answers: dataAnswer,
			})
			if(!hideToast) {
				yield put(successMessage(data.message))
			}
			const answered = data.answers.filter((e) => e.answered)
			const ids = answered.map((e) => e.id)

			const questions = yield select(questionsSelector)

			const currentQuestionIndex = questions.findIndex(
				(e) => e.id === questionId
			)
			const currentQuestion = questions[currentQuestionIndex]
			const { answers } = currentQuestion

			const newAnswers = answers.map((e) => {
				e.answered = ids.some((i) => i === e.id)
				return e
			})
			const newQuestions = [...questions]
			newQuestions[currentQuestionIndex].answers = newAnswers
			yield put(setQuestions(newQuestions))
			if(!!progress) {
				yield put(setProgress(progress))
			}
			typeof cb === "function" && cb()
			if (payload.finish && typeof payload.finish === "function") {
				yield put(sendQuizFinish({ postId: lessonId, cb: payload.finish }))
			}
		} catch (err) {
			console.log(err)
		}
	}
}

function* sendQuizFinishSaga() {
	while (true) {
		const {
			payload: { postId, cb },
		} = yield take(SEND_QUIZ_FINISH)
		yield put(addGlobalLoadItem(SEND_QUIZ_FINISH))
		const { id, product } = yield select(postSelector)
		try {
			const { data } = yield call(sendQuizFinishRequest, postId)
			yield put(setScore(data.score))
			if (data.modules) {
				yield put(setModules(data.modules))
				yield put(setCourseModules(data.modules))
			}
			if (data.navigation) {
				yield put(setNavigatios(data.navigation))
			}

			const lesson = yield select(lessonSelector)
			const productId = lesson?.post?.product?.id

			const params = { productId }
			if (product?.path_id) {
				params["queryString"] = `filter[path_id]=${product?.path_id}`
			}
			
			yield put(getProduct(params))
				
			yield put(getLesson(postId)) 

			if (typeof cb === "function") {
				yield call(cb)
			}
		} catch (err) {
			console.log(err)
		} finally {
			yield put(removeGlobalLoadItem(SEND_QUIZ_FINISH))
		}
	}
}


function* tryAgainQuizSaga() {
	while (true) {
		const {
			payload: { lessonId, cb },
		} = yield take(TRY_AGAIN_QUIZ)
		yield put(addGlobalLoadItem(TRY_AGAIN_QUIZ))
		try {
			const { data } = yield call(tryAgainQuizRequest, lessonId)
			yield put(getLesson(lessonId))

			if (typeof cb === 'function') {
				yield call(cb) 
			  }

		} catch (err) {
			console.log(err)
		} finally {
			yield put(removeGlobalLoadItem(TRY_AGAIN_QUIZ))
		}
	}
}


export function* lessonSagas() {
	yield all([
		sendQuizFinishSaga(),
		sendQuizAnswerSaga(),
		sendQuizStartSaga(),
		sendHomeWorkFormStart(),
		getLessonSaga(),
		sendAttachmentsSaga(),
		takeEvery(SEND_LESSON_STATUS, sendStatusSaga),
		tryAgainQuizSaga()
	])
}
