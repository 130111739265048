import React, { useMemo, useState, useEffect } from "react"
import styles from "./answer.module.sass"
import { Link, useLocation, useParams } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"
import { Form, Formik, useFormikContext } from "formik"
import { createTextArea } from "../UIKit/textArea/textArea"
import FileControl from "../forms/input-control/file-control/FileControl"
import Button from "../forms/button/Button"
import {
	answersSelector,
	lessonSelector,
	navigationSelector,
	sendHomeworkForm,
} from "../../store/reducers/lesson"
import { useTranslation } from "react-i18next"
import Typography from "../UIKit/base/Typography"
import classNames from "classnames"
import FormikValueObserverControl from "../forms/FormikValueObserverControl/FormikValueObserverControl"

function validate(values) {
	let errors = {}
	if (!values.answer && !values.attachment.length) {
		errors.answer = ` must only have at most one of these field`
		errors.attachment = ` must only have at most one of these field`
		return errors
	}
}

const Answer = () => {
	const { postId, productId } = useParams()
	const answers = useSelector(answersSelector)
	const dispatch = useDispatch()
	const navigation = useSelector(navigationSelector)
	const [successModal, setSuccessModal] = useState(false)
	const { t } = useTranslation()

	const lesson = useSelector(lessonSelector)

	let location = useLocation()
	const state = location?.state

	const isPending = useMemo(() => {
		return answers?.some((e) => e.status === "pending")
	}, [answers])

	const isApproved = useMemo(() => {
		return answers?.some((e) => e.status === "approved");
	}, [answers]); 

	const isFailed = useMemo(() => {
		return lesson?.post?.status === "failed";
	}, [lesson]);  

	function handleCloseModal() {
		setSuccessModal(false)
	}

	const handleSubmit = (values, props) => {
		if (isPending || isApproved) {
			return props?.resetForm()
		}
		const { setSubmitting } = props
		dispatch(
			sendHomeworkForm({
				...values,
				postId,
				setSubmitting,
				cb: () => {
					setSuccessModal(true)
					props?.resetForm()
				},
			})
		)
	}

	const initialValues = useMemo(
		() => ({ attachment: [], answer: "" }),
		[postId]
	)

	return (
		<>
		{(!isPending && !isApproved) && !isFailed &&
			<Formik
				onSubmit={handleSubmit}
				validate={validate}
				initialValues={initialValues}
				enableReinitialize={true}
			>
				{({ values, handleChange, dirty, isValid, isSubmitting }) => {
					return (
						<Form className={styles.form}>
							<FormikValueObserverControl/>
							<ResetForm postId={postId} handleCloseModal={handleCloseModal} />
							<div className={styles.card}>
								<div className={styles.input}>
									<Typography
										component="textarea"
										className={classNames(styles.textarea)}
										name="answer" 
										value={values["answer"]}
										onChange={handleChange}
										maxLength={2048}
										placeholder={t("enter_homework_answer")}
										label={t("homework_answer")}
									/>
									</div>

									{!values["file"] || values["file"].length < 4 ? (
										<div className={styles.moduleUpload}>
											<FileControl
												name="attachment"
												maxLength={4} //TODO:
											/>
											<Typography component="p">
												{t("max_number_of_files")} {t("limit_for_size")} {t("file_formats")}
											</Typography>
										</div>
									) : null}

									<div>
										<Button
											disabled={!(dirty && isValid && !isPending) || isSubmitting}
											type="submit"
										>
											send
										</Button>
									</div>
								</div>
							</Form>
						)
					}}
				</Formik>
			} 
		</>
	)
}

const ResetForm = ({ postId, handleCloseModal }) => {
	const { resetForm } = useFormikContext()

	useEffect(() => {
		resetForm()
		handleCloseModal()
	}, [postId])

	return <></>
}

export default Answer
